import styled from '@emotion/styled';
import colors from "../../styles/modules/colors.module.scss";
import rhythm from '../../utils/rhythm';

const Styles = styled.div({
  '.ais-HierarchicalMenu': {
    '>ul': {
      listStyle: 'none',
      padding: 0,
      marginBottom: rhythm(1),
    },

    a: {
      color: colors.secondary,
    },

    '.ais-HierarchicalMenu-item--selected>a': {
      fontWeight: 'bold',
    },

    '.ais-HierarchicalMenu-count': {
      display: 'none',
    }
  },

  '.search-hits': {
    '.ais-Hits-list': {
      listStyle: 'none',
      marginBlockStart: 0,
      marginBlockEnd: 0,
      marginInlineStart: 0,
      marginInlineEnd: 0,
      paddingInlineStart: 0,
    },
  },

  '.accordion&:hover': {
    cursor: 'pointer',
  },
});

export default Styles;
