import React from 'react';
import { connectRefinementList } from 'react-instantsearch-dom';
import { Accordion } from 'react-bootstrap';

import Styles from './customRefinementList.styles';

const RefinementList = ({ attribute, items, refine, defaultRefinement, filterChanged, showMore, limit, label }) => (
  <Styles>
    <Accordion>
      <Accordion.Toggle as='h4' eventKey={label} className='d-block bg-primary text-white p-2'>
        {label}
      </Accordion.Toggle>
      <Accordion.Collapse eventKey={label}>
        <ul className='list-unstyled'>
          {items.map(item => (
            <li key={item.label} className='d-inline-block mr-1 mb-1'>
              <button
                onClick={event => {
                  event.preventDefault();
                  filterChanged(item.value);
                  refine(item.value);
                }}
                className={item.isRefined ? 'btn btn-sm btn-primary btn-refinement' : 'btn btn-sm btn-outline-primary btn-refinement'}
              >
                {item.label} ({item.count})
              </button>
            </li>
          ))}
        </ul>
      </Accordion.Collapse>
    </Accordion>
  </Styles>
);

const CustomRefinementList = connectRefinementList(RefinementList);

export default CustomRefinementList;
