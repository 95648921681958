import React from 'react';
import {graphql, Link} from 'gatsby';
import {Col, Container, Row} from "react-bootstrap";
import BackgroundImage from "gatsby-background-image/index";

import Layout from '../components/layout/layout';
import Seo from '../components/seo/seo';
import Search from '../components/search/search';
import HeaderCol from '../components/headerCol/headerCol';
import Swirl from '../images/backgrounds/bg-swirl.svg';
const searchIndices = [{ name: `resources`, title: `resources` }]


const BrowsePage = ({ data }) => {
  // Map ACF group content to variable
  const content = data.wpPage.pageBrowse;

  return (
    <Layout>
      <Seo
        title={data.wpPage.seo.metaTitle}
        description={data.wpPage.seo.metaDesc}
        image={data.wpPage.seo.opengraphImage?.localFile.publicURL}
        ogTitle={data.wpPage.seo.opengraphTitle}
        ogDescription={data.wpPage.seo.opengraphDescription}
        ogImage={data.wpPage.seo.opengraphImage?.localFile.publicURL}
        twitterTitle={data.wpPage.seo.twitterTitle}
        twitterDescription={data.wpPage.seo.twitterDescription}
        twitterImage={data.wpPage.seo.twitterImage?.localFile.publicURL}
      />

      <BackgroundImage
        Tag="header"
        fluid={content.browseHeader.background.localFile.childImageSharp.fluid}
        fadeIn={false}
        style={{ opacity: `1 !important` }}
      >
        <Container>
          <Row>
            <HeaderCol>
              <h1>{content.browseHeader.headline}</h1>
              <p>{content.browseHeader.subheadline}</p>
            </HeaderCol>
          </Row>
        </Container>

        {/* eslint-disable jsx-a11y/alt-text */}
        <img
          src={Swirl}
          style={{opacity: 1, transform: 'translateY(1px)', height: 'auto', width: '100vw'}}
        />
        {/* eslint-enable jsx-a11y/alt-text */}
      </BackgroundImage>

      <section>
        <Container className='py-3 py-md-5'>
          <Search indices={searchIndices} />
        </Container>

        <div className="banner-secondary py-5">
          <Container>
            <Row className="align-items-center">
              <Col md={8}>
                <h4>{content.browseGetStartedBanner.headline}</h4>
              </Col>

              <Col md={4} className='text-center'>
                <Link to="/get-started/" className='btn btn-gradient d-inline-flex'>
                  {content.browseGetStartedBanner.button}
                </Link>
              </Col>
            </Row>
          </Container>
        </div>
      </section>
    </Layout>
  );
};

export const query = graphql`
  query {
    wpPage(databaseId: { eq: 75 }) {
        seo {
            metaDesc
            metaTitle
            twitterDescription
            twitterTitle
            twitterImage {
                localFile {
                    publicURL
                }
            }
            opengraphTitle
            opengraphDescription
            opengraphImage {
                localFile {
                    publicURL
                }
            }
        }
      pageBrowse {
        browseHeader {
          headline
          subheadline
          background {
            localFile {
              childImageSharp {
                fluid {
                  ...GatsbyImageSharpFluid_withWebp
                }
              }
            }
          }
        }
        browseGetStartedBanner {
          headline
          button
        }
      }
    }
  }
`;

export default BrowsePage;
