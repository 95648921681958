import styled from '@emotion/styled';
import rhythm from '../../utils/rhythm';

const Styles = styled.aside({
  h4: {
    '&:hover': {
      cursor: 'pointer',
    },
  },

  '.btn': {
    borderRadius: `${rhythm(1 / 4)} !important`,
  },
});

export default Styles;
