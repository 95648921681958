import React from "react";
import { connectCurrentRefinements } from 'react-instantsearch-dom';

const ClearRefinements = ({ items, refine, clearFilters }) => (
    <button
      onClick={() => {
        refine(items);
        clearFilters();
      }}
      disabled={!items.length}
      className='btn btn-sm btn-outline-primary search-clear'
    >
      Clear All
    </button>
);

const CustomClearRefinements = connectCurrentRefinements(ClearRefinements);

export default CustomClearRefinements;
