import React from "react";
import { connectStats } from 'react-instantsearch-dom';

const Stats = ({ nbHits }) => (
  <div className={`mt-2`}>
    {nbHits} RESOURCES
  </div>
);

const HitCounter = connectStats(Stats);

export default HitCounter;
