import React from "react";
import { connectCurrentRefinements } from 'react-instantsearch-dom';

const CurrentRefinements = ({ items, refine }) => (
    <div className="horizontal-scroll">
      {items.map(item => (
        item.items ? item.items.map(nested => (
          <button className="btn btn-curr-refinement" key={nested.label}>
            {/* eslint-disable jsx-a11y/anchor-is-valid */}
            <a
              href="#"
              onClick={event => {
                event.preventDefault();
                refine(nested.value);
              }}
            >X</a>

            {nested.label}
          </button>
        )) : item.currentRefinement.split('> ')[1] ? <button className="btn btn-curr-refinement" key={item.currentRefinement}>
          <a
            href="#"
            onClick={event => {
              event.preventDefault();
              refine(item.value);
            }}
          >X</a>
          {/* eslint-enable jsx-a11y/anchor-is-valid */}
          { item.currentRefinement.split('> ')[1] }
        </button> : <div key={item.currentRefinement} /> ))}
    </div>
  );

const CustomCurrentRefinements = connectCurrentRefinements(CurrentRefinements);

export default CustomCurrentRefinements;
