import algoliasearch from "algoliasearch/lite"
import {
  InstantSearch,
  SearchBox,
  Highlight,
  Hits,
  Panel,
  VoiceSearch,
  Pagination,
  HierarchicalMenu,
  Configure,
} from 'react-instantsearch-dom';
import React from 'react';
import { Badge, Col, Image, Row, Accordion } from 'react-bootstrap';
import { useQueryParam, StringParam, ArrayParam } from 'use-query-params';

import Styles from './search.styles';
import CustomRefinementList from './customRefinementList';
import CustomClearRefinements from "./customClearRefinements";
import placeholder from '../../images/resource-placeholder.png'
import CustomCurrentRefinements from "./customCurrentRefinements";
import HitCounter from "./hitCounter";
import rhythm from '../../utils/rhythm';

const searchClient = algoliasearch(
  process.env.GATSBY_ALGOLIA_APP_ID,
  process.env.GATSBY_ALGOLIA_SEARCH_KEY
)

export default function Search({ indices }) {
  const options = {year: 'numeric', month: 'short', day: 'numeric' };
  const ResourceHit = ({ hit }) => (
    <Accordion className={`py-3`} style={{ borderBottom: '1px solid gray'}}>
      <Accordion.Toggle as={'div'} eventKey={hit.objectID} style={{ position: 'relative', cursor: 'pointer' }}>
        <div className={`mr-4 mr-xl-5`}>
          <h3 className={`mb-0`}><Highlight attribute="title" hit={hit} tagName="mark" /></h3>
          { hit.date < 2524608000000 ? <h5>{new Date(hit.date).toLocaleDateString('en-US', options)}</h5> : <></> }

          {hit.actionTypes && hit.actionTypes.map((chip, index) =>
            <span key={hit.title + index}>
              <Badge pill variant="secondary" className="mr-1">{chip.slice(0, chip.lastIndexOf('>'))}</Badge>
              <Badge pill variant="secondary" className="mr-1">{chip.slice(chip.lastIndexOf('>') + 1)}</Badge>
            </span>
          )}
          <Badge pill variant="primary">{hit.format}</Badge>
        </div>

        {/* eslint-disable react/jsx-no-target-blank */}
        {hit.linkUrl && <a href={hit.linkUrl} target='_blank' style={{ display: 'block', position: 'absolute', top: 0, right: 0 }}>
          <svg xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-external-link" width="32"
               height="32" viewBox="0 0 24 24" strokeWidth="1" stroke="#004E88" fill="none" strokeLinecap="round"
               strokeLinejoin="round">
            <path stroke="none" d="M0 0h24v24H0z" fill="none" />
            <path d="M11 7h-5a2 2 0 0 0 -2 2v9a2 2 0 0 0 2 2h9a2 2 0 0 0 2 -2v-5" />
            <line x1="10" y1="14" x2="20" y2="4" />
            <polyline points="15 4 20 4 20 9" />
          </svg>
        </a>}
        {/* eslint-enable react/jsx-no-target-blank */}
      </Accordion.Toggle>

      <Accordion.Collapse eventKey={hit.objectID}>
        <Row className={`pt-3`}>
          <Col xs={4}>
            <Image src={hit.coverPhoto ? hit.coverPhoto : placeholder} resizemode='contain' alt="Search Result" />
          </Col>
          <Col xs={8}>
            {hit.creator && <h4 className={`mb-2`}><Highlight attribute="creator" hit={hit} tagName="mark" /></h4>}
            {hit.primaryFocusAreas.length > 0 && <p className='mb-2'><strong>Focus Areas: </strong>{hit.primaryFocusAreas.join(", ")}</p>}
            {hit.description && <p className='d-block'>{hit.description}</p>}
            {hit.focusAreaTopics && <p className='mb-2'><strong>Topics: </strong>{hit.focusAreaTopics.join(", ")}</p>}
          </Col>
        </Row>
      </Accordion.Collapse>
    </Accordion>
  )

  const [q, setSearchParam] = useQueryParam('q', StringParam);
  const [audienceFilters, setAudienceFilters] = useQueryParam('audienceFilters', ArrayParam);
  const [engagementFilters, setEngagementFilters] = useQueryParam('engagementFilters', ArrayParam);
  const [formatFilters, setFormatFilters] = useQueryParam('formatFilters', ArrayParam);
  const [focusFilters, setFocusFilters] = useQueryParam('focusFilters', ArrayParam);
  const [topicsFilters, setTopicsFilters] = useQueryParam('topicsFilters', ArrayParam);
  const [actionFilters, setActionFilters] = useQueryParam('actionFilters', StringParam);

  const audienceFilterChanged = (value => {
    setAudienceFilters(value);
  });

  const engagementFiltersChanged = (value => {
    setEngagementFilters(value);
  });

  const formatFiltersChanged = (value => {
    setFormatFilters(value);
  });

  const focusFiltersChanged = (value => {
    setFocusFilters(value);
  });

  const topicsFiltersChanged = (value => {
    setTopicsFilters(value);
  });

  const clearFilters = () => {
    setAudienceFilters([]);
    setEngagementFilters([]);
    setFormatFilters([]);
    setFocusFilters([]);
    setTopicsFilters([]);
    setActionFilters('');
  }

  return (
    <Styles>
      <InstantSearch
        searchClient={searchClient}
        indexName={indices[0].name}
        onSearchStateChange={searchState => {
          // use the searchState
          if (searchState.hierarchicalMenu) {
            if (searchState.hierarchicalMenu.actionCategories !== actionFilters) {
              setActionFilters(searchState.hierarchicalMenu.actionCategories)
            }
          } else {
            if (!!actionFilters) {
              setActionFilters(null)
            }
          }
        }}
      >
        <Configure
          filters={'date > ' + new Date().getTime()}
        />
        <Panel>
          <Row className='mb-4 d-md-none'
               style={{ position: 'sticky', top: 0, paddingTop: rhythm(1), zIndex: 10, background: '#f7f7f7' }}>
            <Col xs={12} className='d-flex align-items-center search-interface'>
              <SearchBox
                defaultRefinement={q}
                onChange={event => {
                  setSearchParam(event.target.value);
                }}
                translations={{
                  placeholder: 'SEARCH'
                }}
              />

              <div className="d-none d-md-block">
                <VoiceSearch />
              </div>

              <div className="d-none d-md-block">
                <CustomClearRefinements clearFilters={clearFilters} />
              </div>
            </Col>

            <Col xs={12} className={`mt-2`}>
              <CustomCurrentRefinements />

              <HitCounter />
            </Col>
          </Row>

          <Row>
            <Col md={4}>
              <div className='search-sidebar'>
                <div className='search-sidebar-inner'>
                  <h4 className='d-block bg-secondary text-white p-2'>Actions</h4>
                  <HierarchicalMenu
                    defaultRefinement={actionFilters}
                    attributes={['actionCategories', 'actionTypes']}

                    /* eslint-disable array-callback-return */
                    transformItems={items => {
                      items.sort((a, b) => {
                        if (a.label === 'Learn') {
                          return -1;
                        }
                        if (b.label === 'Learn') {
                          return 1;
                        }
                        if (a.label === 'Join') {
                          return 1;
                        }
                        if (b.label === 'Join') {
                          return -1;
                        }
                      });

                      return items.map(item => ({
                        ...item,
                        label: item.label + ' (' + item.count + ')',
                        items: item.items ? item.items.map(i => ({
                          ...i,
                          label: i.label + ' (' + i.count + ')'
                        })) : null
                      }))
                    }}
                    /* eslint-enable array-callback-return */
                  />

                  <CustomRefinementList
                    attribute="primaryFocusAreas"
                    label="Focus Areas"
                    defaultRefinement={focusFilters}
                    filterChanged={focusFiltersChanged}
                  />

                  <CustomRefinementList
                    attribute="focusAreaTopics"
                    label="Topics"
                    defaultRefinement={topicsFilters}
                    filterChanged={topicsFiltersChanged}
                    limit={10}
                  />

                  <CustomRefinementList
                    attribute="targetAudience"
                    label="Audience"
                    defaultRefinement={audienceFilters}
                    filterChanged={audienceFilterChanged}
                  />

                  <CustomRefinementList
                    attribute="engagementLevel"
                    label="Engagement Level"
                    defaultRefinement={engagementFilters}
                    filterChanged={engagementFiltersChanged}
                    transformItems={items => items.sort((a, b) => {
                      if (a.label < b.label) {
                        return -1;
                      }
                      if (b.label < a.label) {
                        return 1;
                      }
                      return 0;
                    })}
                  />

                  <CustomRefinementList
                    attribute="format"
                    label="Format"
                    defaultRefinement={formatFilters}
                    filterChanged={formatFiltersChanged}
                  />
                </div>
              </div>
            </Col>

            <Col md={8} xs={12}>
              <Row className='mb-4 d-none d-md-block'
                   style={{ position: 'sticky', top: 0, paddingTop: rhythm(1), zIndex: 10, background: '#f7f7f7' }}>
                <Col xs={12} className='d-flex align-items-center search-interface'>
                  <SearchBox
                    defaultRefinement={q}
                    onChange={event => {
                      setSearchParam(event.target.value);
                    }}
                    translations={{
                      placeholder: 'SEARCH'
                    }}
                  />

                  <div className="d-none d-md-block">
                    <VoiceSearch />
                  </div>

                  <div className="d-none d-md-block">
                    <CustomClearRefinements clearFilters={clearFilters} />
                  </div>
                </Col>

                <Col xs={12} className={`mt-2`}>
                  <CustomCurrentRefinements />

                  <HitCounter />
                </Col>
              </Row>

              <Hits hitComponent={ResourceHit} className='search-hits' />

              <div className={`mt-3`}>
                <Pagination />
              </div>
            </Col>
          </Row>
        </Panel>
      </InstantSearch>
    </Styles>
  )
}
